import config from "../config";
import ActivityState from '../models/activityState';
import IGraphQlQueryResult from '../models/graphQlQueryResult';
import ISet from "../models/set";
import ISmashggEvent, { IPhaseGroup } from "../models/smashggEvent";
import ITournament from "../models/tournament";
import { QueryParams, createEventsQueryParams, createPhaseGroupSetsQueryParams, createPhaseGroupsQueryParams, createSetsQueryParams, createStreamQueueQueryParams } from "./queryParams";

const HEADERS = {
    'Content-Type': 'application/json',
};

export async function queryEvents(slug: string): Promise<ITournament> {
    const response = await fetch(
        `${config.apiUrl}/tournament/${slug}`
    );
    const result = await response.json();
    const tournament = result.data as ITournament;

    return tournament;
}

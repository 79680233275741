import ISet from "../../models/set";
import ISmashggEvent from "../../models/smashggEvent";
import BaseSetCard from './baseSetCard';
import SetCard from "./setCard";

class SetCardRenderer {
	domNode: HTMLElement;
	RenderClass: typeof BaseSetCard;

	constructor(domNode: HTMLElement, renderClass?: typeof BaseSetCard) {
		this.domNode = domNode;
		this.RenderClass = renderClass || SetCard;
	}

	async renderSets(sets: ISet[], event: ISmashggEvent) {
		this.clear();
		sets.forEach(async set => await this.renderSet(set, event));
	}

	async renderSet(set: ISet, event: ISmashggEvent) {
		const card = new this.RenderClass(set, event);
		this.domNode.appendChild(await card.getDomNode());
	}

	clear() {
		while (this.domNode.firstChild) {
			this.domNode.removeChild(this.domNode.firstChild);
		}
	}
};

export default SetCardRenderer;

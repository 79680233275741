abstract class PrototypeTemplateElement {
	protected static classPrototype: HTMLElement;

	protected element: HTMLElement | null = null;

	constructor(
		protected readonly prototype: HTMLElement
	) {}

	public getDomNode(): HTMLElement {
		if (!this.element) {
			this.element = this.createElement();
		}

		return this.element;
	}

	protected abstract createElement(): HTMLElement;

	protected createPrototype(): HTMLElement {
		return this.prototype.cloneNode(true) as HTMLElement;
	}

	protected static htmlToElement(html: string): HTMLElement {
		const template = document.createElement('template');
		template.innerHTML = html.trim();
		return template.content.firstChild as HTMLElement;
	}
}

export default PrototypeTemplateElement;

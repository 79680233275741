/**
 * Shows / hides an element using inline-style display: none. Will only work properly if the inline
 * style display attribute is not in use otherwise.
 * @param element The element to hide / show
 * @param visible Set to true to show the element, false to hide it, omit to toggle
 */
export function setVisible(element: HTMLElement, visible?: boolean ) {
    const showElement = visible !== undefined ? visible : !!element.style.display;

    if (showElement) {
        element.style.removeProperty('display');
    }
    else {
        element.style.setProperty('display', 'none');
    }
}

function atTop(e: HTMLElement) {
    return e.offsetTop <= 0;
}

function atLeft(e: HTMLElement) {
    return e.offsetLeft <= 0;
}

function atBottom(e: HTMLElement) {
    const bottomPos = e.offsetTop + e.offsetHeight;
    const distanceToParentBottom = e.parentElement!.clientHeight - bottomPos;
    return distanceToParentBottom <= 0;
}

function atRight(e: HTMLElement) {
    const rightPos = e.offsetLeft + e.offsetWidth;
    const distanceToParentRight = e.parentElement!.clientWidth - rightPos;
    return distanceToParentRight <= 0;
}

function moveElement(e: HTMLElement, directions: IDirections, incrementPx: number) {
    const d = directions;

    const checkHorizontal = d.right ? atRight : atLeft;
    const checkVertical = d.down ? atBottom : atTop;


    if (checkHorizontal(e)) {
        d.right = !d.right;
    }

    if (checkVertical(e)) {
        d.down = !d.down;
    }

    const newLeft = e.offsetLeft + (d.right ? incrementPx : -incrementPx);
    const newTop = e.offsetTop + (d.down ? incrementPx : -incrementPx);

    e.style.setProperty('left', `${newLeft}px`);
    e.style.setProperty('top', `${newTop}px`);
}

interface IDirections {
    right: boolean;
    down: boolean;
}

export default class DvdScreensaver {
    public readonly intervalMs: number;
    public readonly incrementPx: number;
    public get isVisible() {
        return this.isElementVisible;
    }

    private directions: IDirections = {
        right: true,
        down: true,
    };
    private interval?: ReturnType<typeof setInterval>;
    private isElementVisible: boolean;
    private element: HTMLElement
    private elementToHide?: HTMLElement

    constructor(element: HTMLElement, elementToHide?: HTMLElement, intervalMs: number = 10, incrementPx: number = 1) {
        this.element = element;
        this.elementToHide = elementToHide;

        this.intervalMs = intervalMs;
        this.incrementPx = incrementPx;

        element.style.setProperty('position', 'absolute');
        this.isElementVisible = (elementToHide || element).style.display !== 'none';
    }

    public start(): DvdScreensaver {
        if (this.interval !== undefined || !this.isElementVisible) return this;

        this.interval = setInterval(
            () => moveElement(this.element, this.directions, this.incrementPx),
            this.intervalMs
        );

        return this;
    }

    public stop(): DvdScreensaver {
        if (this.interval === undefined) return this;

        clearInterval(this.interval);
        delete this.interval;

        return this;
    }

    public setVisible(visible?: boolean): DvdScreensaver {
        const elem = this.elementToHide || this.element;
        const showElem = visible !== undefined ? visible : !!elem.style.display;

        if (showElem) {
            elem.style.removeProperty('display');
        }
        else {
            elem.style.setProperty('display', 'none');
        }

        this.isElementVisible = showElem;

        return this;
    }

    public hide(): DvdScreensaver {
        this.setVisible(false);
        return this;
    }

    public show(): DvdScreensaver {
        this.setVisible(true);
        return this;
    }
}

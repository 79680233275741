export enum BackgroundType {
    Cover = "cover",
    Unset = "unset",
}

export enum BackgroundSource {
    Standard = "Standard",
    Custom = "custom",
    TournamentBanner = "tournament-banner",
}

function makeRoundText(set, event) {
	var phaseGroup = set.phaseGroup;
	var phase = event.phases.find(p => p.phaseGroups.nodes.find(pg => pg.id === phaseGroup.id));
	var roundText = "";

	roundText += phase.name + " / ";

	if (phase.groupCount > 1) { // are there multiple phase groups?
		// use the phase group identifier as well in that case
		roundText += phaseGroup.displayIdentifier;
		roundText += " / ";
	}

	roundText += set.fullRoundText; // finally add the round name in the phase group

	return roundText;
}

function prettyDateToTime(timestamp) { //TODO: put into module
	if (!timestamp) return " ";
	var date = new Date(parseInt(timestamp));
	return date.toLocaleTimeString(navigator.language, {
		hour: '2-digit',
		minute:'2-digit'
	});
}

function getEntrantNameForSlot(slot) {
	var name = "";
	if (slot.entrant && slot.entrant.name) name = slot.entrant.name;
	return name;
}

export { makeRoundText, getEntrantNameForSlot, prettyDateToTime };
import ISet from '../models/set';
import { IPhaseGroup } from '../models/smashggEvent';

function mapSetsToPhaseGroups(sets, phaseGroups) {
	var pgs = [];
	phaseGroups.forEach(pg => pgs[pg.id] = {phaseGroup: pg, sets: []});
	return sets.map(function mapSets(set) {
		return new SetPhaseGroupMap(set, pgs[set.phaseGroup.id].phaseGroup);
	});
}

function compareSetPhaseMapsByWaveStart(x, y) {
	// null/empty checks
	if (!x.phaseGroup.wave || !y.phaseGroup.wave) return 0;
	if (!x.phaseGroup.wave.startAt || !y.phaseGroup.wave.startAt) return 0;

	// check whether x starts before y
	return x.phaseGroup.wave.startAt - y.phaseGroup.wave.startAt;
}

// filter function to remove upcoming sets in waves that are too far in the future (over waveThreshold)
// set filterEmpty to true if sets without wave/start time info should be removed, too
function filterSetMapsByWaveStart(setPhaseMap, waveThreshold, filterEmpty) {
	if (setPhaseMap.phaseGroup.wave && setPhaseMap.phaseGroup.wave.startAt) {
		var offsetMs = setPhaseMap.phaseGroup.wave.startAt*1000 - Date.now();
		return offsetMs < waveThreshold;
	} else return !filterEmpty;
}

function sortSetsByPhaseGroupStart(sets, phaseGroups, returnMaps) {
	var maps = mapSetsToPhaseGroups(sets, phaseGroups);
	maps.sort(compareSetPhaseMapsByWaveStart);
	if (returnMaps) return maps;
	else return maps.map(m => m.set);
}

class SetPhaseGroupMap {
	constructor(
		public readonly set: ISet,
		public readonly phaseGroup: IPhaseGroup,
	) {}
}

const setSorting = {
	SetPhaseGroupMap: SetPhaseGroupMap,
	sortSetsByPhaseGroupStart: sortSetsByPhaseGroupStart,
	mapSetsToPhaseGroups: mapSetsToPhaseGroups,
	comparers: {
		compareSetMapsByWaveStart: compareSetPhaseMapsByWaveStart
	},
	filters: {
		filterSetMapsByWaveStart
	}
};

export default setSorting;
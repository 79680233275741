import html from './template/set-card.html';
import { prettyDateToTime } from '../../smashgg/util/texts';
import ISet from '../../models/set';
import ISmashggEvent from '../../models/smashggEvent';
import BaseSetCard from './baseSetCard';

export default class SetCard extends BaseSetCard {
	protected static classPrototype = this.htmlToElement(html);

	protected set: ISet;
	protected event: ISmashggEvent;
	protected stationElement?: HTMLDivElement;
	protected stationHeadingElement?: HTMLDivElement;
	protected stationBoxElement?: HTMLDivElement;
	protected timeElement?: HTMLDivElement;

	constructor(set: ISet, event: ISmashggEvent) {
		super(set, event, SetCard.classPrototype.cloneNode(true) as HTMLElement);
		this.set = set;
		this.event = event;
	}

	protected override createElement(): HTMLElement {
		this.element = super.createElement();

		// prepare nodes
		this.stationElement = this.element.querySelector('.station') as HTMLDivElement;
		this.stationHeadingElement = this.element.querySelector('.station-heading') as HTMLDivElement;
		this.stationBoxElement = this.element.querySelector('.station-box') as HTMLDivElement;
		this.timeElement = this.element.querySelector('.time') as HTMLDivElement;

		let stationString = '-';
		if (this.set.stream) {
			stationString = '';
			this.stationHeadingElement.innerHTML = 'On Stream';
			this.element.classList.add('stream');
			this.stationElement.classList.add('stream');
		}
		else if (this.set.station) {
			stationString = this.set.station.number;
		}

		let timeString = prettyDateToTime(this.set.startedAt*1000);
		if (this.set.state === 1) {
			timeString = 'Upcoming';
			this.element.classList.add('upcoming');
		}

		this.stationElement.innerHTML = stationString;
		this.timeElement.innerHTML = timeString;

		return this.element;
	}
}

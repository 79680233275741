import html from './template/basic-set-card.html';
import PrototypeTemplateElement from '../prototypeTemplateElement';
import { makeRoundText, getEntrantNameForSlot } from '../../smashgg/util/texts';
import ISet from '../../models/set';
import ISmashggEvent from '../../models/smashggEvent';

export default class BaseSetCard extends PrototypeTemplateElement {
	protected static classPrototype = this.htmlToElement(html);

	protected roundNameElement?: HTMLDivElement;
	protected entrantAElement?: HTMLDivElement;
	protected entrantBElement?: HTMLDivElement;

	constructor(
		protected readonly set: ISet,
		protected readonly event: ISmashggEvent,
		prototype?: HTMLElement,
	) {
		super(prototype || BaseSetCard.classPrototype.cloneNode(true) as HTMLElement);
	}

	protected createElement(): HTMLElement {
		this.element = this.prototype;

		// prepare nodes
		this.roundNameElement = this.element.querySelector('.round-name') as HTMLDivElement;
		this.entrantAElement = this.element.querySelector('.entrant-a') as HTMLDivElement;
		this.entrantBElement = this.element.querySelector('.entrant-b') as HTMLDivElement;

		const entrantNames = this.set.slots.map(getEntrantNameForSlot);

		this.roundNameElement.innerHTML = makeRoundText(this.set, this.event);

		if (this.set.state === 1) {
			this.element.classList.add('upcoming');
		}

		this.entrantAElement.innerHTML = entrantNames[0];
		this.entrantBElement.innerHTML = entrantNames[1];

		return this.element;
	}
}

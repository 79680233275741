const paramNames = [
    'tournament',
    'event',
    'perRow',
    'background',
    'backgroundType',
];

class DashboardUrlParams {
    private _params: URLSearchParams;
    tournament?: string;
    event?: number;
    perRow: number;
    background?: string;
    backgroundType?: string;
    theme: string;

    constructor() {
        this._params = new URLSearchParams(location.search);
        this.tournament = this._params.get('tournament') || undefined;

        const eventParam = this._params.get('event')
        if (eventParam) {
            const eventNum = parseInt(eventParam, 10);
            this.event = !isNaN(eventNum) ? eventNum : undefined;
        }

        const perRowParam = this._params.get('perRow') || "5";
        this.perRow = parseInt(perRowParam, 10) || 5;

        this.background = this._params.get('background') || undefined;
        this.backgroundType = this._params.get('backgroundType') || undefined;

        this.theme = this._params.get('theme') || 'fancy';
    }

    get isValid(): boolean {
        return this.tournament != null && this.event != null;
    }

    pushUrl(
        tournament: string,
        event: number,
        perRow?: number,
        background?: string,
        backgroundType?: string,
        theme?: string,
    ): void {
        const query = new URLSearchParams();

        query.append('tournament', tournament);
        query.append('event', event.toFixed(0));

        perRow !== undefined && query.append('perRow', perRow.toFixed(0));
        background && query.append('background', background);
        backgroundType && query.append('backgroundType', backgroundType);
        theme && query.append('theme', theme);

        const queryString = '?' + query.toString();
        history.pushState(null, '', queryString);
    }
}

const params = new DashboardUrlParams();

export default params;
import * as signalR from '@microsoft/signalr';
import { BackgroundType, BackgroundSource } from './models/backgroundSettings';
import config from './config';
import ISet from './models/set';
import ISmashggEvent, { IPhaseGroup } from './models/smashggEvent';
import IStreamQueue from "./models/streamQueue";
import { queryEvents } from './smashgg/query';
import setSorting from './smashgg/set-sorting';
import stylesheet from './util/stylesheet';
import { setVisible } from "./util/dom-utils";
import DvdScreensaver from "./util/dvd-screensaver";
import DisplayType from './domUtils/displayType';
import { getRadioValue } from './util/radioButtonUtils';
import ITournament, { IStream } from './models/tournament';
import urlParams from './dashboard-url-params';
import AppStyle from './ui/appStyle';
import SetCardRenderer from './ui/set-cards/setCardRenderer';
import BaseSetCard from './ui/set-cards/baseSetCard';

export default class DashboardController {
    // Data models
    tournament?: ITournament;
    events: ISmashggEvent[] = [];
    currentEvent?: ISmashggEvent;

    // Renderers
    activeSetCardRenderer: any; // TODO: update with class
    upcomingSetCardRenderer: any; // TODO: update with class
    streamQueueSetCardRenderer: SetCardRenderer;

    // Other variables
    clockTimerID?: ReturnType<typeof setTimeout>;
    bgUrlTimeout?: ReturnType<typeof setTimeout>;
    backgroundUrl?: string;
    backgroundType?: string;
    theme?: string;
    noConnectionScreensaver: DvdScreensaver;

    // Buttons
    goButton: HTMLButtonElement;
    startButton: HTMLButtonElement;

    // Inputs
    tournamentSlugInput: HTMLInputElement;
    eventsSelect: HTMLSelectElement;
    cardsPerRowInput: HTMLInputElement;
    backgroundUrlInput: HTMLInputElement;
    backgroundSourceRadioButtons: HTMLInputElement[];
    backgroundTypeRadioButtons: HTMLInputElement[];
    styleRadioButtons: HTMLInputElement[];

    // Other elements
    headerDiv: HTMLDivElement;
    contentDiv: HTMLDivElement;
    eventControlsDiv: HTMLDivElement;
    tournamentNameDiv: HTMLDivElement;
    tournamentHashtagDiv: HTMLDivElement;
    tournamentLinkDiv: HTMLDivElement;
    streamLinkDiv: HTMLDivElement;
    noConnectionMessageOverlayDiv: HTMLDivElement;
    noConnectionMessageDiv: HTMLDivElement;
    streamQueueDiv: HTMLDivElement;
    activeSetsContainerNode: HTMLDivElement;
    upcomingSetsContainerNode: HTMLDivElement;
    streamQueueContainerNode: HTMLDivElement;
    cardsContainerDivs: NodeListOf<HTMLDivElement>;
    clockDiv: HTMLDivElement;
    tournamentImage?: HTMLImageElement;
    customBackgroundUrlDiv: HTMLDivElement
    backgroundTypeSettingsDiv: HTMLDivElement;
    styleLinks: {
        fancy: HTMLLinkElement | null;
        dark: HTMLLinkElement | null;
    } = {
        fancy: null,
        dark: null,
    };

    constructor() {
        this.goButton = document.querySelector("#goButton") as HTMLButtonElement;
        this.startButton = document.querySelector("#start") as HTMLButtonElement;
        this.eventsSelect = document.querySelector("#eventsSelect") as HTMLSelectElement;

        this.tournamentImage = document.querySelector("#tournamentImage") as HTMLImageElement;

        this.headerDiv = document.querySelector("#header") as HTMLDivElement;
        this.contentDiv = document.querySelector("#content") as HTMLDivElement;
        this.clockDiv = document.querySelector("#clock") as HTMLDivElement;
        this.eventControlsDiv = document.querySelector("#event-controls") as HTMLDivElement;
        this.tournamentNameDiv = document.querySelector("#tournamentName") as HTMLDivElement;
        this.tournamentHashtagDiv = document.querySelector("#tournamentHashtag") as HTMLDivElement;
        this.tournamentLinkDiv = document.querySelector("#tournamentLink") as HTMLDivElement;
        this.streamLinkDiv = document.querySelector("#streamLinks") as HTMLDivElement;
        this.streamQueueDiv = document.querySelector("#stream-queue") as HTMLDivElement;
        this.customBackgroundUrlDiv = document.querySelector("#custom-background") as HTMLDivElement;
        this.backgroundTypeSettingsDiv = document.querySelector("#background-type") as HTMLDivElement;
        this.activeSetsContainerNode = document.querySelector("#sets") as HTMLDivElement;
        this.upcomingSetsContainerNode = document.querySelector("#upcoming-sets") as HTMLDivElement;
        this.streamQueueContainerNode = document.querySelector("#stream-sets") as HTMLDivElement;
        this.noConnectionMessageOverlayDiv = document.querySelector('#noConnectionMessageOverlay') as HTMLDivElement;
        this.noConnectionMessageDiv = document.querySelector('#noConnectionMessage') as HTMLDivElement;

        this.cardsContainerDivs = document.querySelectorAll(".cards-container");

        this.tournamentSlugInput = document.querySelector("#tournamentSlugInput") as HTMLInputElement;
        this.cardsPerRowInput = document.querySelector("#cards-per-row") as HTMLInputElement;
        this.backgroundUrlInput = document.querySelector("#bg-url") as HTMLInputElement;

        this.backgroundSourceRadioButtons = Array.from(document.getElementsByName("bg-src")) as HTMLInputElement[];
        this.backgroundTypeRadioButtons = Array.from(document.getElementsByName("bg-type")) as HTMLInputElement[];
        this.styleRadioButtons = Array.from(document.getElementsByName("style")) as HTMLInputElement[];


        this.refreshClock();
        this.clockTimerID = setInterval(() => this.refreshClock(), 5000);

        this.activeSetCardRenderer = new SetCardRenderer(this.activeSetsContainerNode);
        this.upcomingSetCardRenderer = new SetCardRenderer(this.upcomingSetsContainerNode);
        this.streamQueueSetCardRenderer = new SetCardRenderer(this.streamQueueContainerNode, BaseSetCard);

        this.addEventListeners();

        this.init();

        this.noConnectionScreensaver = new DvdScreensaver(
            this.noConnectionMessageDiv,
            this.noConnectionMessageOverlayDiv
        );
    }

    async init() {
        this.applyThemeFromControls();
        let loaded = false;
        if (urlParams.isValid) {
            loaded = await this.loadParams();
        }
        if (!loaded) this.eventControlsDiv.style.display = DisplayType.Block;
    }

    refreshClock(): void {
        // TODO: refactor into own class, save last refresh and only update when time changed
        const today = new Date();
        const h = today.getHours();
        const m = today.getMinutes();

        // Add leading zeroes if necessary
        let mString: string;
        if (m < 10) {
            mString = "0" + m;
        } else {
            mString = m.toString();
        }
        this.clockDiv.innerHTML = `${h}:${mString}`
    }

    async loadParams(): Promise<boolean> {
        if (!urlParams.isValid) return false; // TODO: partial load with tournament only - display event select

        if (urlParams.theme) {
            this.setAppTheme(urlParams.theme);
        }

        await this.loadSlug(urlParams.tournament!);

        this.loadEvent(this.events[urlParams.event!], urlParams.perRow);
        this.setBackgroundFromParams();


        return true;
    }

    async loadSlug(slug: string): Promise<void> {
        const result = await queryEvents(slug);
        this.processResult(result);
    }

    loadEvent(event: ISmashggEvent, cardsPerRow: number): void {
        this.currentEvent = event;
        this.switchToCardsView();
        this.setCardsPerRow(cardsPerRow);
        this.activateRefreshing();
    }

    // UI look and feel methods

    setBackground(url: string) {
        this.backgroundUrl = url;
        document.body.style.backgroundImage = `url("${url}")`;
    }

    setBackgroundFromParams(): void {
        if (!urlParams.background) return;
        if (urlParams.background.toLowerCase() === "banner") {
            this.setTournamentBannerBackground();
        } else {
            this.setCustomBackground(urlParams.background);
        }
        if (urlParams.backgroundType) {
            this.setBackgroundType(urlParams.backgroundType);
        } else {
            this.setBackgroundType("cover");
        }
    }

    setBackgroundType(type?: string): void {
        const bgSrc = getRadioValue(this.backgroundSourceRadioButtons);
        if (bgSrc === BackgroundSource.Standard) return;

        const bgType = getRadioValue(this.backgroundTypeRadioButtons);
        if (!(type || bgType)) {
            throw new Error("Could not retrieve background type from radio buttons!");
        }
        this.backgroundType = type || bgType!;
        document.body.style.backgroundSize = this.backgroundType;
    }

    setTournamentBannerBackground(): void {
        this.setCustomBackgroundSettingsVisibility(false);
        this.setBackgroundTypeSettingsVisibility(true);
        const banner = this.tournament?.images?.find(i => i.type === "banner"); // TODO enum
        if (!banner) {
            alert("The tournament does not have a banner image.");
            return;
        }
        this.setBackground(banner.url);
        this.setBackgroundType();
    }

    setCustomBackground(url?: string): void {
        const bgUrl = url || this.backgroundUrlInput.value.trim();
        if (!bgUrl) return;
        // TODO fix existence check CORS issue
        // // if URL changed - check if exists
        // if (bgUrl !== backgroundUrl && !exists(bgUrl)) {
        //     resetBackground();
        // }
        // else setBackground(bgUrl);
        this.setBackground(bgUrl);
        this.setBackgroundType();
    }

    private applyThemeFromControls() {
        const style = getRadioValue(this.styleRadioButtons);
        style && this.setAppTheme(style);
    }

    setAppTheme(theme: string): void {
        if (theme === AppStyle.Standard) {
            if (this.styleLinks.fancy) {
                stylesheet.remove(this.styleLinks.fancy);
                this.styleLinks.fancy = null;
            }
            if (this.styleLinks.dark) {
                stylesheet.remove(this.styleLinks.dark);
                this.styleLinks.dark = null;
            }
        }
        else if (theme === AppStyle.Fancy) {
            if (!this.styleLinks.fancy) this.styleLinks.fancy = stylesheet.add("./css/fancy.css");
            if (this.styleLinks.dark) {
                stylesheet.remove(this.styleLinks.dark)
                this.styleLinks.dark = null;
            }
        }
        else if (theme === AppStyle.Dark) {
            if (!this.styleLinks.fancy) this.styleLinks.fancy = stylesheet.add("./css/fancy.css");
            if (!this.styleLinks.dark) this.styleLinks.dark = stylesheet.add("./css/dark.css");
        }

        this.theme = theme;
    }

    setCustomBackgroundSettingsVisibility(visible: boolean): void {
        const value = visible ? DisplayType.Block : DisplayType.None;
        this.customBackgroundUrlDiv.style.display = value;
        if (visible) {
            this.resetBackground();
            this.setBackgroundTypeSettingsVisibility(true);
            this.setCustomBackground();
        }
    }

    resetBackground(): void {
        document.body.style.backgroundImage = "";
        document.body.style.backgroundSize = "";
    }

    setBackgroundTypeSettingsVisibility(visible: boolean) {
        const value = visible ? DisplayType.Block : DisplayType.None;
        this.backgroundTypeSettingsDiv.style.display = value;
    }

    // UI control methods

    addEventListeners() {
        this.goButton.addEventListener("click", () => this.goButton_onClick());
        this.tournamentSlugInput.addEventListener("keyup", event => this.tournamentSlugInput_onKeyup(event));
        this.startButton.addEventListener("click", () => this.startButton_onClick());
        this.backgroundUrlInput.addEventListener("keyup", () => this.backgroundUrl_onChange());
        this.backgroundSourceRadioButtons.forEach(r => r.addEventListener("click", () => this.backgroundSource_onChange()));
        this.backgroundTypeRadioButtons.forEach(r => r.addEventListener("click", () => this.setBackgroundType()));
        this.styleRadioButtons.forEach(r => r.addEventListener("click", () => this.styleRadioButtons_onClick()));
    }

    setCardsPerRow(numCards: number): void {
        for (const c of this.cardsContainerDivs) {
            c.style.setProperty('--cards', numCards.toString());
        }
    }

    // Hides set up controls and starts displaying set cards
    switchToCardsView(): void {
        this.eventControlsDiv.style.display = "none";
        this.contentDiv.style.display = "flex";
    }

    async activateRefreshing() {
        this.subscribeToEvent();
    }

    async subscribeToEvent() {
        if (!this.currentEvent) {
            console.log('No event selected, cannot subscribe to set updates yet.');
            return;
        }

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(config.hubUrl)
            .build();

            await connection.start();
            connection.on('setsUpdated', setsJson => this.updateSets(JSON.parse(setsJson)));
            connection.on('streamQueuesUpdated', streamQueuesJson => this.updateStreamQueues(JSON.parse(streamQueuesJson)));
            connection.invoke('registerForEvent', this.currentEvent.id, this.tournament?.slug || '');
            connection.onreconnecting(() => console.log('Reconnecting'));
            connection.onclose(() => {
                this.onSignalRDisconnected(connection);
            });
            connection.onreconnected(() => {
                console.log('Reconnected to server');
                if (this.currentEvent) {
                    connection.invoke('registerForEvent', this.currentEvent.id, this.tournament?.slug || '');
                }
            });
    }

    private onSignalRDisconnected(connection: signalR.HubConnection) {
        console.log('Connection closed');
        this.noConnectionScreensaver.show().start();
        this.reconnectToServer(connection);
    }

    async reconnectToServer(connection: signalR.HubConnection) {
        // TODO: indicate offline status
        console.log('Reconnecting to server');
        connection.stop();
        try {
            await this.subscribeToEvent();
            this.noConnectionScreensaver.stop().hide();
        }
        catch (e) {
            console.log('Failed to reconnect to server, retrying in 5 seconds');
            setTimeout (() => this.reconnectToServer(connection), 5000);
        }
    }

    async updateSets(sets: ISet[]) {
        let activeSets;
        let upcomingSets;

        // TODO check case when there are no more matches (call clear())
        if (sets) {
            // there was an API bug with states, so I'll filter by state:
            activeSets = sets.filter(set => set.state === 2);
            upcomingSets = sets.filter(set => set.state === 1);
            this.activeSetCardRenderer.renderSets(activeSets, this.currentEvent);
        }


        if (upcomingSets && upcomingSets.length > 0) {
            // filter sets with less than two entrants
            upcomingSets = upcomingSets.filter(
                s => s.slots.filter(s => s.entrant).length > 1
            );

            // there was an API bug with states, so I'll filter by state:
            upcomingSets = upcomingSets.filter(set => set.state === 1);

            const phaseGroups = this.currentEvent.phases?.reduce((pgs, cur) => {
                pgs = pgs.concat(cur.phaseGroups.nodes);
                return pgs;
            }, [] as IPhaseGroup[]);

            const sortedMaps = setSorting.sortSetsByPhaseGroupStart(upcomingSets, phaseGroups, true);

            // filter out sets in phase groups that are too far in the future
            const filtered = sortedMaps.filter(m => setSorting.filters.filterSetMapsByWaveStart(m, config.waveThreshold, false));

            const displayedSets = filtered.map(m => m.set);

            this.upcomingSetCardRenderer.renderSets(displayedSets, this.currentEvent);
        }
    }

    displayCards(): void {
        this.eventControlsDiv.style.display = DisplayType.None;
        this.contentDiv.style.display = DisplayType.Flex;
    }

    async updateStreamQueues(streamQueues: IStreamQueue[]): Promise<void> {
        if (!this.tournament?.slug) {
            throw new Error("No tournament or slug defined!");
        }

        if (!streamQueues.length) {
            this.streamQueueDiv.style.display = DisplayType.None;
            return;
        }

        for (const streamQueue of streamQueues) {
            if (!streamQueue.sets.length) {
                continue;
            }

            this.streamQueueDiv.style.display = DisplayType.Flex;
            this.streamQueueSetCardRenderer.renderSets(streamQueue.sets, this.currentEvent!);
        }
    }

    fillEventsSelect(events: ISmashggEvent[]): void {
        while (this.eventsSelect.firstChild) {
            this.eventsSelect.removeChild(this.eventsSelect.firstChild);
        }

        const promptOption = document.createElement("option");
        promptOption.innerHTML = "--- Please choose ---";
        promptOption.value = "-1";
        this.eventsSelect.appendChild(promptOption);

        events.forEach((event) => {
            const option = document.createElement("option");
            option.innerHTML = event.name || "";
            option.value = event.id.toString();
            this.eventsSelect.appendChild(option);
        });
    }

    clearStreamDivs(): void {
        while (this.streamLinkDiv.firstChild) {
            this.streamLinkDiv.removeChild(this.streamLinkDiv.firstChild); //TODO: make helper class
        }
    }

    addStreamLink(stream: IStream): void {
        const streamLink = this.formatStream(stream);
        const span = document.createElement("span");
        span.innerHTML = streamLink;
        this.streamLinkDiv.appendChild(span);
    }

    resetRadios(): void {
        this.backgroundSourceRadioButtons[0].checked = true;
        this.backgroundTypeRadioButtons[0].checked = true;
    }

    setStandardBackground(): void {
        this.setCustomBackgroundSettingsVisibility(false);
        this.setBackgroundTypeSettingsVisibility(false);
        this.resetBackground();
    }

    // Event handlers

    goButton_onClick(): void {
        if (this.tournamentSlugInput) {
            const slug = this.tournamentSlugInput.value;
            this.loadSlug(slug);
        }
    }

    tournamentSlugInput_onKeyup(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            this.tournamentSlugInput.blur();
            this.goButton_onClick();
        }
    }

    startButton_onClick(): void {
        const selIndex = this.eventsSelect.selectedIndex;
        if (selIndex !== 0) {
            this.displayCards();
            const eventIndex = selIndex - 1;
            this.currentEvent = this.events[eventIndex];
            let cardsPerRow = this.cardsPerRowInput.value;
            let cardsPerRowNumeric = Number(cardsPerRow);
            if (isNaN(cardsPerRowNumeric)) {
                cardsPerRow = '5';
                cardsPerRowNumeric = 5;
            }
            this.cardsContainerDivs.forEach(c => c.style.setProperty('--cards', cardsPerRow));
            this.activateRefreshing();

            urlParams.pushUrl(
                this.tournament?.shortSlug || this.tournament?.slug!,
                eventIndex,
                cardsPerRowNumeric,
                this.backgroundUrl,
                this.backgroundType,
                this.theme,
            )
        }
    }

    backgroundSource_onChange(): void {
        const src = getRadioValue(this.backgroundSourceRadioButtons);
        switch (src) {
            case "standard": this.setStandardBackground(); break;
            case "tournament-banner": this.setTournamentBannerBackground(); break;
            case "custom": this.setCustomBackgroundSettingsVisibility(true); break;
        }
    }

    styleRadioButtons_onClick(): void {
        this.applyThemeFromControls();
    }

    backgroundUrl_onChange(): void {
        if (this.bgUrlTimeout) clearTimeout(this.bgUrlTimeout);
        this.bgUrlTimeout = setTimeout(() => {
            this.backgroundSettings_onChange();
        }, 500);
    }

    backgroundSettings_onChange() {
        const bgSrc = getRadioValue(this.backgroundSourceRadioButtons);
        if (bgSrc === BackgroundSource.Custom) {
            this.setCustomBackground();
        }
    }

    // Data processing methods

    processResult(tournament: ITournament): void { // TODO: IResult type {data: {tournament: Tournament}}
        this.resetRadios();
        this.setStandardBackground();
        this.tournament = tournament;
        this.events = this.tournament!.events || [];
        const tournamentProfileImage = this.tournament?.images?.find(i => i.type === "profile");
        if (tournamentProfileImage && this.tournamentImage) {
            this.tournamentImage.setAttribute("src", tournamentProfileImage.url);
        }
        this.tournamentNameDiv.innerHTML = this.tournament?.name || "";
        if (this.tournament?.hashtag) {
            this.tournamentHashtagDiv.innerHTML = this.tournament.hashtag;
        }

        const slug = this.tournament?.shortSlug || this.tournament?.slug || "";
        this.tournamentLinkDiv.innerHTML = `smash.gg/${slug}`;
        if (this.tournament?.streams) {
            this.clearStreamDivs();
            this.tournament.streams.forEach(stream => this.addStreamLink(stream));
        }

        setVisible(this.headerDiv, true);

        if (!tournament.events) {
            throw new Error('No events in tournament!');
        }

        this.fillEventsSelect(tournament.events);

        const bannerBackgroundRadioButton = this.backgroundSourceRadioButtons.find(r => r.value === "tournament-banner");
        const banner = this.tournament?.images?.find(i => i.type === "banner");

        if (!bannerBackgroundRadioButton) {
            throw new Error("Could not retrieve radio button input!");
        }

        if (banner) {
             bannerBackgroundRadioButton.disabled = false;
        }
        else {
            bannerBackgroundRadioButton.disabled = true;
        }

    }

    formatStream(stream: IStream) {
        let siteBaseUrl;
        switch(stream.streamSource) {
            // TODO: enum?
            case "TWITCH": siteBaseUrl = "twitch.tv"; break;
            case "HITBOX": siteBaseUrl = "smashcast.tv"; break;
            case "STREAMME": siteBaseUrl = "stream.me"; break;
            case "MIXER": siteBaseUrl = "mixer.com"; break;
            default: siteBaseUrl = stream.streamSource.toLowerCase();;
        }
        return `${siteBaseUrl}/${stream.streamName}`;
    }
}

enum DisplayType {
    None = "none",
    Inline = "inline",
    Block = "block",
    Contents = "contents",
    LisItem = "list-item",
    InlineBlock = "inline-block",
    InlineTable = "inline-table",
    Table = "table",
    TableCell = "table-cell",
    TableColumn = "table-column",
    TableColumnGroup = "table-column-group",
    TableFooterGroup = "table-footer-group",
    TableHeaderGroup = "table-header-group",
    TableRow = "table-row",
    TableRowGroup = "table-row-group",
    Flex = "flex",
    InlineFlex = "inline-flex",
    Grid = "grid",
    InlineGrid = "inline-grid",
    Ruby = "ruby",
    RubyBase = "ruby-base",
    RubyText = "ruby-text",
    RubyBaseContainer = "ruby-base-container",
    RubyTextContainer = "ruby-text-container",
    RunIn = "run-in",
    Inherit = "inherit",
    Initial = "initial",
    Unset = "unset",
}

export default DisplayType;

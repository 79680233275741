function addStylesheet(fileName) {
	var head = document.head;
	var link = document.createElement("link");
	link.type = "text/css";
	link.rel = "stylesheet";
	link.href = fileName;
	return head.appendChild(link);
}

function removeStylesheet(link) {
	document.head.removeChild(link);
}

var stylesheet = {
	add: addStylesheet,
	remove: removeStylesheet
};

export default stylesheet;